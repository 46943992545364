.chat {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-users {
  width: 21vw;
  background-color: #161616;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.chat-content {
  height: 100vh;
  flex: 1;
  position: relative;
}
.chat-scroll {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 100px;
  overflow: auto;
  padding: 15px 0;
}
.chat-header {
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1.5vw;
  height: 100px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.chat-user-block {
  height: 100px;
  width: 100%;
  border-top: 1px solid #4D4D4D;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chat-messages-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  font-weight: 600;
  font-size: 1.5vw;
  color: #1A1E25;
  border-bottom: 1px solid #4D4D4D;
  font-family: Gilroy-Light;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}
.chat-messages-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-input {
  width: 85%;
  height: 60px;
  background: #D9D9D9;
  border-radius: 50px;
  font-family: Gilroy-Light;
  font-size: 1vw;
  color: #1A1E25;
  outline: none;
  padding: 0 25px;
}
.chat-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-image: url('../images/send.png');
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: 55% 50%;
  margin: 0 15px;
  cursor: pointer;
}
.chat-indent {
  width: 100%;
  height: 100px;
}
.chat-date {
  text-align: center;
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1vw;
  color: #8A8A8A;
  padding: 15px;
}
.chat-message {
  width: 100%;
  display: flex;
  color: #333538;
}
.chat-bubble {
  position: relative;
  padding: 10px 25px 50px 25px;
  font-family: Gilroy-Light;
  font-weight: 400;
  font-size: 1.145vw;
  line-height: 1.5vw;
  width: 60%;
  background: #EEEEEE;
  border-radius: 20px;
  margin: 10px 0;
  word-wrap: break-word;
}
.chat-left {
  justify-content: flex-start;
}
.chat-right {
  justify-content: flex-end;
}
.chat-time {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: Gilroy-Light;
  font-weight: 400;
  font-size: 1vw;
  line-height: 24px;
  color: #8A8A8A;
  width: 90px;
  padding-bottom: 5px;
  background-size: 28%;
  background-repeat: no-repeat;
  background-position: 80% 15%;
}
.chat-read {
  background-image: url('../images/read.png');
}
.chat-unread {
  background-image: url('../images/unread.png');
}