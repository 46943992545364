.auth {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-form {
  display: block;
  padding: 20px 30px;
  width: 540px;
  min-height: 280px;
  background-color: #383838;
  border-radius: 10px;
  color: #FFFFFF;
}
.auth-header {
  font-family: Gilroy-ExtraBold;
  text-align: center;
  font-size: 24px;
  padding-top: 10px;
}
.auth-error {
  text-align: center;
  color: #FF0000;
  font-family: Gilroy-Light;
  font-size: 18px;
  text-shadow: 0 0 1px #FFFFFF;
  padding: 10px;
}
.auth-input-block {
  width: 100%;
  margin: 10px auto;
}
.auth-input-block > div {
  font-family: Gilroy-Light;
  font-size: 18px;
}
.auth-input-block > input {
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 5px;
  font-family: Gilroy-Light;
  font-size: 18px;
  color: #383838;
  outline: none;
  padding: 0 15px;
}
.auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  color: #383838;
  background-color: #FFFFFF;
  margin: 20px auto 0;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
}