.accounts-header {
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1.5vw;
  color: #1A1E25;
  height: 100px;
  display: flex;
  align-items: center;
}
.accounts-content {
  display: flex;
  justify-content: center;
  height: 85vh;
}
.accounts-password-visible {
  position: absolute;
  top: 0;
  right: 0.2vw;
  width: 2.5vw;
  bottom: 0;
  z-index: 1;
  background: url('../images/password-visible.png') center center no-repeat;
  background-size: 50%;
  cursor: pointer;
}
.accounts-user-block {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #DEDEDE;
  height: 4.2vw;
  margin-bottom: 1vw;
}
.accounts-avatar {
  width: 3.125vw;
  height: 3.125vw;
  background: #D9D9D9;
  border-radius: 50%;
  position: relative;
}
.accounts-name {
  font-family: Gilroy-Light;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 1.3vw;
  color: #1A1E25;
  padding: 0.7vw 1.5vw;
  width: 70%;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}