.main-header {
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1.5vw;
  color: #1A1E25;
  height: 100px;
  display: flex;
  align-items: center;
}
.main-content {
  display: flex;
  flex-wrap: wrap;
}
.main-card {
  width: 22.6vw;
  height: 9.16vw;
  background: #F2F2F2;
  border-radius: 10px;
  margin-left: 2.4vw;
  margin-bottom: 2.4vw;
  cursor: pointer;
  position: relative;
}
.main-card-header {
  position: absolute;
  left: 1.4vw;
  top: 1.4vw;
  color: #1A1E25;
  font-family: Gilroy-Light;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 1.5vw;
  width: 50%;
}
.main-card-bottom-action {
  position: absolute;
  left: 1.4vw;
  bottom: 1.4vw;
  color: #1A1E25;
  font-family: Gilroy-Light;
  font-weight: 700;
  font-size: 0.83vw;
  width: 50%;
}
.main-card-bottom-info {
  position: absolute;
  text-align: right;
  right: 1.4vw;
  top: 1.4vw;
  color: #1A1E25;
  font-family: Gilroy-Light;
  font-size: 0.83vw;
  width: 50%;
}