@font-face {
  font-family: Gilroy-ExtraBold;
  src: url('../fonts/Gilroy-ExtraBold.otf');
}
@font-face {
  font-family: Gilroy-Light;
  src: url('../fonts/Gilroy-Light.otf');
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Gilroy-Light;
}
.admin {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sides-padding {
  padding: 0 2.8vw;
}
.avatar {
  width: 3.125vw;
  height: 3.125vw;
  background: #D9D9D9;
  border-radius: 50%;
  position: relative;
}
.name {
  font-family: Gilroy-Light;
  font-weight: 500;
  font-size: 1.3vw;
  line-height: 1.3vw;
  color: #FFFFFF;
  padding: 0 1.5vw;
  width: 70%;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.user-active {
  font-family: Gilroy-ExtraBold !important;
}
.content {
  flex: 1;
  height: 100vh;
}
.notif {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FF0000;
  color: #FFFFFF;
  font-family: Gilroy-Light;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notif-menu {
  position: absolute;
  left: -27px;
  top: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FF0000;
  color: #FFFFFF;
  font-family: Gilroy-Light;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chapter-content {
  display: flex;
  flex-direction: column;
  margin: 0 1.35vw;
}
.header {
  width: 22.6vw;
  height: 8vw;
  background: #F2F2F2;
  border-radius: 10px;
  font-family: Gilroy-Light;
  font-weight: 700;
  font-size: 1.3vw;
  padding: 2vw;
  position: relative;
}
.header::after {
  content: '';
  position: absolute;
  top: 0.8vw;
  right: 0.8vw;
  width: 2vw;
  height: 2vw;
  background: url('../images/icon.png') center center no-repeat;
  background-size: 80%;
}
.chapter {
  width: 22.6vw;
  flex: 1;
  background: #F2F2F2;
  border-radius: 10px;
  margin-top: 1.5vw;
  padding: 2vw;
}
.input {
  width: 100%;
  margin-bottom: 1.2vw;
}
.input > .label {
  font-family: Gilroy-Light;
  font-weight: 700;
  font-size: 0.8vw;
  color: #1A1E25;
}
.input input {
  width: 100%;
  height: 2.5vw;
  border: 0;
  background: #FFFFFF;
  border-radius: 5px;
  outline: none;
  padding: 1vw 3vw 1vw 1vw;
  color: #1A1E25;
  font-family: Gilroy-Light;
  font-size: 0.8vw;
}
.input textarea {
  width: 100%;
  height: 10vw;
  border: 0;
  background: #FFFFFF;
  border-radius: 5px;
  outline: none;
  padding: 1vw 3vw 1vw 1vw;
  color: #1A1E25;
  font-family: Gilroy-Light;
  font-size: 0.8vw;
  user-select: none;
  outline: none;
  resize: none;
}
.input-block {
  position: relative;
  margin-top: 0.6vw;
}
.input-error {
  border: 1px solid #FF0000 !important;
}
.chapter-button {
  width: 100%;
  height: 2.7vw;
  background: #1A1E25;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: Gilroy-Light;
  font-size: 0.9vw;
  cursor: pointer;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.input-params {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 0.6vw;
}
.input-params input {
  width: 48%;
  height: 2.5vw;
  border: 0;
  background: #FFFFFF;
  border-radius: 5px;
  outline: none;
  padding: 1vw 3vw 1vw 1vw;
  color: #1A1E25;
  font-family: Gilroy-Light;
  font-size: 0.8vw;
}