.requests {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.requests-users {
  width: 21vw;
  background-color: #161616;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.requests-content {
  height: 100vh;
  flex: 1;
  position: relative;
}
.requests-scroll {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  overflow: auto;
  padding: 15px 0;
}
.requests-header {
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1.5vw;
  height: 100px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.requests-user-block {
  height: 100px;
  width: 100%;
  border-top: 1px solid #4D4D4D;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.requests-messages-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  font-weight: 600;
  font-size: 1.5vw;
  color: #1A1E25;
  border-bottom: 1px solid #4D4D4D;
  font-family: Gilroy-Light;
  display: flex;
  align-items: center;
}
.requests-messages-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.requests-input {
  width: 85%;
  height: 60px;
  background: #D9D9D9;
  border-radius: 50px;
  font-family: Gilroy-Light;
  font-size: 1vw;
  color: #1A1E25;
  outline: none;
  padding: 0 25px;
}
.requests-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-image: url('../images/send.png');
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: 55% 50%;
  margin: 0 15px;
  cursor: pointer;
}
.requests-indent {
  width: 100%;
  height: 100px;
}
.requests-date {
  text-align: center;
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1vw;
  color: #8A8A8A;
  padding: 15px;
}
.requests-message {
  width: 100%;
  display: flex;
  color: #333538;
}
.requests-bubble {
  position: relative;
  padding: 10px 25px 50px 25px;
  font-family: Gilroy-Light;
  font-weight: 400;
  font-size: 1.145vw;
  line-height: 1.5vw;
  width: 60%;
  background: #EEEEEE;
  border-radius: 20px;
  margin: 10px 0;
  word-wrap: break-word;
  cursor: pointer;
}
.requests-right {
  justify-content: flex-end;
}
.requests-time {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: Gilroy-Light;
  font-weight: 400;
  font-size: 1vw;
  line-height: 24px;
  color: #8A8A8A;
  padding: 0 22px 5px 0;
}
.requests-status {
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: Gilroy-Light;
  font-size: 1vw;
  line-height: 24px;
  padding: 0 0 5px 22px;
  font-weight: bold;
}
.requests-statuses-modal-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
}
.requests-statuses-modal {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background: #f4f4f4;
  border-radius: 15px;
  overflow: hidden;
}
.requests-statuses-close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  background: url('../images/close.png') no-repeat;
  background-size: contain;
  cursor: pointer;
}
.requests-statuses-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 100px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 30px;
}
.requests-statuses-header-padding {
  padding: 0 2.8vw;
}
.request-name {
  font-size: 20px;
  padding-top: 10px;
}
.requests-status-bubble {
  width: 90%;
  padding: 15px 25px;
  border-radius: 20px;
  margin-bottom: 40px;
}
.requests-status-bubble > div {
  margin: 10px 0;
}
.requests-statuses-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 140px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
}
.request-status-input {
  width: 60%;
  height: 100px;
  background: #D9D9D9;
  border-radius: 25px;
  font-family: Gilroy-Light;
  font-size: 1vw;
  color: #1A1E25;
  outline: none;
  padding: 20px;
}
.request-status-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-image: url('../images/send.png');
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: 55% 50%;
  margin: 0 15px;
  cursor: pointer;
}
.request-status-type-block {
  height: 100%;
}
.request-status-new {
  color: #D9D9D9;
  padding: 13px 0;
  font-family: Gilroy-Light;
  font-size: 1vw;
}
.request-status-select {
  font-family: Gilroy-Light;
  font-weight: bold;
  font-size: .8vw;
  padding: 3px;
  user-select: none;
  cursor: pointer;
  border-radius: 2px;
}
.request-status-info {
  font-family: Gilroy-Light;
}
.request-status-info > span {
  font-size: .7vw;
}
.request-status-info > b {
  font-size: .9vw;
}
.requests-list-block {
  margin-top: 20px;
}
.requests-list {
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  color: #597892
}