.notifications-header {
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1.5vw;
  color: #1A1E25;
  height: 100px;
  display: flex;
  align-items: center;
}
.notification-block {
  margin-left: 2.4vw;
}
.input-select {
  width: 100%;
  height: 30px;
  border: 1px solid #383838;
  user-select: none;
  border-radius: 5px;
  margin-bottom: -7px;
}