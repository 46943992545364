.menu {
  width: 21vw;
  background-color: #383838;
  height: 100vh;
}
.menu-header {
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 2vw;
  height: 100px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.menu-user-block {
  height: 100px;
  width: 100%;
  border-top: 1px solid #4D4D4D;
  border-bottom: 1px solid #4D4D4D;
  display: flex;
  align-items: center;
}
.menu-list {
  margin-top: 35px;
}
.menu-list > li {
  font-family: Gilroy-Light;
  font-size: 1.25vw;
  line-height: 3.125vw;
  color: #FFFFFF;
  list-style-type: none;
  cursor: pointer;
  margin-left: 0;
  padding-left: 0;
  -ms-user-select: none; 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}
.menu-li-active {
  font-family: Gilroy-ExtraBold !important;
}